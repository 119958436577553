import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export const defaultErrorSnackbar = {
	show: true,
	msg: 'Oops, an error occurred. Please try again later.',
	severity: 'error',
};

export default function PositionedSnackbar({
	showmsg = false,
	hidemsg,
	msg = '',
	isInputSheet = false,
	duration = 3000,
	severity = 'info',
}) {
	return (
		<>
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: isInputSheet ? 'top' : 'bottom',
						horizontal: isInputSheet ? 'right' : 'center',
					}}
					sx={
						isInputSheet
							? {
									top: `20px !important`,
									right: `160px !important`,
							  }
							: {}
					}
					open={showmsg}
					autoHideDuration={duration}
					onClose={hidemsg}
					message={msg}>
					<Alert
						onClose={hidemsg}
						severity={severity}
						variant="filled"
						color={severity}>
						{msg}
					</Alert>
				</Snackbar>
			</div>
		</>
	);
}
