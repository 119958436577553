import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	basDeviceNetworkConfigurationPageKeys,
	basJsonKeys,
} from '../../const';
import { ActionButtonGroup } from '../../CommonTemplates';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';

const BASRequestPortAccess = ({ setShowSnackbar, setPageNumber }) => {
	const dispatch = useDispatch();

	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;

	const { energySetupData } = useSelector((state) => state.setup) || {};
	const { has_given_port_access } = energySetupData || {};

	const [hasGivenPortAccess, setHasGivenPortAccess] = useState(
		has_given_port_access ?? null
	);

	const [loading, setLoading] = useState(false);

	const handleCheckboxChange = (event) => {
		setHasGivenPortAccess(event.target.checked);
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			await dispatch(
				updateEnergySetup({
					propertyId,
					column_name: basJsonKeys.HAS_GIVEN_PORT_ACCESS,
					column_value: hasGivenPortAccess,
					...(energySetupData.id && { id: energySetupData.id }),
				})
			);

			// Only proceed after the dispatch is complete
			setLoading(false);
			setPageNumber(2);
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
			setLoading(false); // Ensure loading state is reset even on failure
		}
	};

	return (
		<>
			<p>
				Please coordinatewith your IT department to help set up the firewall
				rules below for the assigned IP address to connect to network and cloud
				over VPN.
			</p>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gap: '16px',
					margin: '20px 0',
				}}>
				<ul>
					<li style={{ marginBottom: '10px' }}>HTTP (Port 80)</li>
					<li style={{ marginBottom: '10px' }}>HTTPS (Port 443)</li>
					<li style={{ marginBottom: '10px' }}>BACnet (Port 47808)</li>
					<li style={{ marginBottom: '10px' }}>Modbus (Port 502)</li>
					<li style={{ marginBottom: '10px' }}>DNS (Port 53)</li>
					<li style={{ marginBottom: '10px' }}>DHCP (Ports 67 and 68)</li>
				</ul>
				<ul>
					<li style={{ marginBottom: '10px' }}>
						NTP (Port 123) - Network time
					</li>
					<li style={{ marginBottom: '10px' }}>ICMP (Port 20) - Ping status</li>
					<li style={{ marginBottom: '10px' }}>SMTP (Port 587) - Mailing</li>
					<li style={{ marginBottom: '10px' }}>VPN (Port 50051/TCP)</li>
					<li style={{ marginBottom: '10px' }}>VPN (Port 505/TCP)</li>
					<li style={{ marginBottom: '10px' }}>AWS (Port 6543)</li>
				</ul>
			</div>
			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				<input
					type="checkbox"
					id="acknowledge"
					checked={hasGivenPortAccess}
					onChange={handleCheckboxChange}
				/>
				<label htmlFor="acknowledge" style={{ marginLeft: '8px' }}>
					Please confirm that access has been granted to all the above ports.
				</label>
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				<ActionButtonGroup
					pageToNavigateOnBack={
						basDeviceNetworkConfigurationPageKeys.DEVICE_NETWORK_CONFIGURATION
					}
					setPageNumber={setPageNumber}
					disableCTA={loading}
					ctaClickHandler={handleSubmit}
				/>
			</div>
		</>
	);
};

export default BASRequestPortAccess;
