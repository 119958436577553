import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import toggleButtonStyles from '../../../../inputSheet/ToggleButton.module.scss';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';
import { useDispatch } from 'react-redux';
import { energyDirectPageKeys } from '../../const';

export function AccountExists({
	energySetupData,
	propertyId,
	column_name,
	setPageNumber,
}) {
	const dispatch = useDispatch();

	const latestValue = energySetupData[column_name];

	const handleToggleChange = (_, column_value) => {
		setPageNumber(
			column_value
				? energyDirectPageKeys.UPLOAD_UTILITY_BILL
				: energyDirectPageKeys.UPLOAD_CONSUMPTION_DATA
		);
		dispatch(
			updateEnergySetup({
				propertyId,
				column_name,
				column_value,
				...(energySetupData.id && { id: energySetupData.id }),
			})
		);
		dispatch(updateEnergyRow({ [column_name]: column_value }));
	};

	return (
		<div className="InputContainer">
			<label htmlFor={'hasEnergyDirectAccount'}>
				<span className="labelTitle">
					Do you have an Energy Direct account?
				</span>
				Joulea will request access to your Energy Direct accounts as a secondary
				user to directly collect demand and cost data.
			</label>
			<ToggleButtonGroup
				className={toggleButtonStyles.popupButtonGroupContainer}
				value={latestValue}
				exclusive
				onChange={handleToggleChange}>
				{Object.entries({
					Yes: true,
					No: false,
				}).map(([label, value]) => (
					<ToggleButton
						key={label}
						value={value}
						className={toggleButtonStyles.popupButton}>
						{label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	);
}
