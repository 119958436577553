import { useState } from 'react';
import { basDeviceNetworkConfigurationPageKeys } from '../../const';
import { CongratsTemplate } from '../../CommonTemplates';
import BASDeviceNetworkConfiguration from './BASDeviceNetworkConfiguration';
import BASRequestPortAccess from './BASRequestPortAccess';

const DeviceNetworkConfigurationOnboarding = ({ onClose, setShowSnackbar }) => {
	const [pageNumber, setPageNumber] = useState(0);

	const networkConfigurationPages = {
		[basDeviceNetworkConfigurationPageKeys.DEVICE_NETWORK_CONFIGURATION]: (
			<BASDeviceNetworkConfiguration
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basDeviceNetworkConfigurationPageKeys.REQUEST_PORT_ACCESS]: (
			<BASRequestPortAccess
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basDeviceNetworkConfigurationPageKeys.SUCCESS]: (
			<DeviceNetworkConfigurationSuccess onClose={onClose} />
		),
	};

	return <>{networkConfigurationPages[pageNumber]}</>;
};

function DeviceNetworkConfigurationSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your information has been saved successfully."
			onClick={onClose}
		/>
	);
}

export default DeviceNetworkConfigurationOnboarding;
