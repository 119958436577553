import FileUpload from '../buildingDocuments/FileUpload';
import { ActionButtonGroup } from '../../CommonTemplates';
import { UTILITY_BILL_TYPE_ID, CONSUMPTION_DOC_TYPE_ID } from '../../const';
import { energyDirectPageKeys } from '../../const';
import {
	ErrorTemplate,
	LoadingTemplate,
} from '../../../../uiTemplates/MessageTemplates';

export function UploadUtilityBill({
	setShowSnackbar,
	buildingName,
	propertyId,
	setPageNumber,
	documentDefinition,
	documentDefinitionLoading,
	documentDefinitionError,
	documentList,
	documentListError,
}) {
	const utilityBillDefinition = documentDefinition.find(
		(doc) => doc.id === UTILITY_BILL_TYPE_ID
	);
	const uploadedUtilityBills = documentList.filter(
		(doc) => doc?.document_type_id?.id === UTILITY_BILL_TYPE_ID
	);

	if (documentDefinitionLoading) {
		return <LoadingTemplate />;
	}

	if (documentDefinitionError || documentListError) {
		return <ErrorTemplate />;
	}

	return (
		<>
			<p>
				Please upload a utility bill from each of {buildingName}’s energy direct
				account. Once received, we’ll request you for access as a secondary
				user.
			</p>
			<div className="FileUploadContainer">
				<FileUpload
					documentTypeIndex={UTILITY_BILL_TYPE_ID}
					documentName={utilityBillDefinition?.name}
					description={utilityBillDefinition?.description}
					inputJsonKey={utilityBillDefinition?.jsonKey}
					propertyId={propertyId}
					setShowSnackbar={setShowSnackbar}
					allowedFileTypes={utilityBillDefinition?.allowedFileTypes}
					documents={uploadedUtilityBills}
					isRequired={utilityBillDefinition?.isRequired}
				/>
			</div>
			<ActionButtonGroup
				pageToNavigateOnBack={energyDirectPageKeys.ACCOUNT_EXISTS}
				setPageNumber={setPageNumber}
				disableCTA={!uploadedUtilityBills?.length}
				ctaClickHandler={() => {
					setPageNumber(energyDirectPageKeys.UTILITY_BILL_SUCCESS);
				}}
			/>
		</>
	);
}
export function UploadConsumptionData({
	setShowSnackbar,
	buildingName,
	propertyId,
	setPageNumber,
	documentDefinition,
	documentDefinitionLoading,
	documentDefinitionError,
	documentList,
	documentListError,
}) {
	const consumptionDocDefinition = documentDefinition.find(
		(doc) => doc.id === CONSUMPTION_DOC_TYPE_ID
	);
	const uploadedConsumptionDoc = documentList.filter(
		(doc) => doc?.document_type_id?.id === CONSUMPTION_DOC_TYPE_ID
	);

	if (documentDefinitionLoading) {
		return <LoadingTemplate />;
	}

	if (documentDefinitionError || documentListError) {
		return <ErrorTemplate />;
	}

	return (
		<>
			<p>
				Please upload {buildingName}’s consumption data of at least the past 1
				year.
			</p>
			<div className="FileUploadContainer">
				<FileUpload
					documentTypeIndex={CONSUMPTION_DOC_TYPE_ID}
					documentName={consumptionDocDefinition?.name}
					description={consumptionDocDefinition?.description}
					inputJsonKey={consumptionDocDefinition?.jsonKey}
					propertyId={propertyId}
					setShowSnackbar={setShowSnackbar}
					allowedFileTypes={consumptionDocDefinition?.allowedFileTypes}
					documents={uploadedConsumptionDoc}
					isRequired={consumptionDocDefinition?.isRequired}
				/>
			</div>
			<ActionButtonGroup
				pageToNavigateOnBack={energyDirectPageKeys.ACCOUNT_EXISTS}
				setPageNumber={setPageNumber}
				disableCTA={!uploadedConsumptionDoc?.length}
				ctaClickHandler={() => {
					setPageNumber(energyDirectPageKeys.CONSUMPTION_DATA_SUCCESS);
				}}
			/>
		</>
	);
}
