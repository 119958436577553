import { useDispatch, useSelector } from 'react-redux';
import '../../DataSharing.scss';
import { CongratsTemplate } from '../../CommonTemplates';
import { AccountExists } from './AccountExists';
import {
	UploadUtilityBill,
	UploadConsumptionData,
} from './UploadEnergyDirectDocuments';
import { useEffect, useState } from 'react';
import { getEnergySetup } from '../../../../../redux/setup/setupSlice';
import { energyDirectPageKeys, DOES_ED_ACCOUNT_EXIST } from '../../const';
import { getInitialEnergyDirectPageNumber } from '../../const';

function EnergyDirectOnboarding({ onClose, setShowSnackbar }) {
	const dispatch = useDispatch();
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;
	const { energySetupData } = useSelector((state) => state.setup);

	// The document definition and list
	const {
		documentDefinition,
		documentDefinitionLoading,
		documentDefinitionError,
		documentList,
		documentListError,
	} = useSelector((state) => state.setup);

	// The current page number
	const [pageNumber, setPageNumber] = useState(
		getInitialEnergyDirectPageNumber(energySetupData, documentList)
	);

	useEffect(() => {
		if (propertyId) {
			// Fetch the energy setup data
			dispatch(getEnergySetup({ propertyId }));
		}
	}, [dispatch, propertyId]);

	// The props to pass to the upload components
	const propsToUploadFileComponenent = {
		buildingName: selectedBuilding.prop_name,
		propertyId: selectedBuilding.prop_id,
		setShowSnackbar,
		setPageNumber,
		documentDefinition,
		documentDefinitionLoading,
		documentDefinitionError,
		documentList,
		documentListError,
	};

	const energyDirectPages = {
		[energyDirectPageKeys.ACCOUNT_EXISTS]: (
			<AccountExists
				energySetupData={energySetupData}
				propertyId={propertyId}
				column_name={DOES_ED_ACCOUNT_EXIST}
				setPageNumber={setPageNumber}
			/>
		),
		[energyDirectPageKeys.UPLOAD_UTILITY_BILL]: (
			<UploadUtilityBill {...propsToUploadFileComponenent} />
		),
		[energyDirectPageKeys.UTILITY_BILL_SUCCESS]: (
			<UtilityBillSuccess {...{ onClose }} />
		),
		[energyDirectPageKeys.UPLOAD_CONSUMPTION_DATA]: (
			<UploadConsumptionData {...propsToUploadFileComponenent} />
		),
		[energyDirectPageKeys.CONSUMPTION_DATA_SUCCESS]: (
			<ConsumptionDataSuccess {...{ onClose }} />
		),
	};

	return <p>{energyDirectPages[pageNumber]}</p>;
}

export default EnergyDirectOnboarding;

function UtilityBillSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Heads up!"
			message="Primary users of the Energy Direct accounts will soon get emails asking for secondary user access. Please accept these requests."
			onClick={onClose}
		/>
	);
}

function ConsumptionDataSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your consumption data has been received. We'll assess it shortly and will be in touch if necessary."
			onClick={onClose}
		/>
	);
}
