import congrats from '../../../assets/illustrations/congrats.svg';
import { Button } from '@mui/material';
import styles from '../../../pages/InputSheet.module.scss';
import buttonStyles from '../../uiTemplates/Button.module.scss';

export function CongratsTemplate({
	onClick,
	title,
	message,
	buttonText = 'Got it!',
	onBackClick = null,
	additionalTitleStyles = {},
}) {
	return (
		<div className={styles.savePopupContainer}>
			<div
				className={styles.savePopupTitleContainer}
				style={additionalTitleStyles}>
				<img src={congrats} alt="congrats" />
				<div className={styles.text}>
					<h2 className={styles.title}>{title}</h2>
					<p>{message}</p>
				</div>
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				{onBackClick && (
					<Button style={{ textDecoration: 'underline' }} onClick={onBackClick}>
						Back
					</Button>
				)}
				<Button variant="contained" onClick={onClick}>
					{buttonText}
				</Button>
			</div>
		</div>
	);
}
export function ActionButtonGroup({
	pageToNavigateOnBack,
	setPageNumber,
	buttonText = 'Submit',
	disableCTA = false,
	ctaClickHandler,
}) {
	return (
		<div className={buttonStyles.dataSharingButtons}>
			<Button
				style={{ textDecoration: 'underline' }}
				onClick={() => {
					setPageNumber((prev) =>
						pageToNavigateOnBack || pageToNavigateOnBack === 0
							? pageToNavigateOnBack
							: prev - 1
					);
				}}>
				Back
			</Button>
			{ctaClickHandler && (
				<Button
					variant="contained"
					onClick={ctaClickHandler}
					disabled={disableCTA}>
					{buttonText}
				</Button>
			)}
		</div>
	);
}

// Action buttons that increment and decrement the current page
export function SimpleActionButtonGroup({
	setCurrentPage,
	ctaText = 'Next',
	showBack = true,
}) {
	return (
		<div className={buttonStyles.dataSharingButtons}>
			{showBack && (
				<Button
					style={{ textDecoration: 'underline' }}
					onClick={() => {
						setCurrentPage((currentPage) => currentPage - 1);
					}}>
					Back
				</Button>
			)}
			<Button
				variant="contained"
				onClick={() => {
					setCurrentPage((currentPage) => currentPage + 1);
				}}>
				{ctaText}
			</Button>
		</div>
	);
}

export function InstructionStep({ stepNumber, stepDescription }) {
	return (
		<div className="step">
			<span className="step-number">{stepNumber}</span>
			<span className="step-description">{stepDescription}</span>
		</div>
	);
}
