import { Button, CircularProgress } from '@mui/material';
import ErrorLogo from '../../assets/illustrations/issue.svg';
import SuccessLogo from '../../assets/illustrations/congrats.svg';
import WarningLogo from '../../assets/illustrations/no-data-found-warning.svg';
import NoBuildingFoundLogo from '../../assets/illustrations/buildings-not-found.svg';
import WorkInProgressIllustration from '../../assets/illustrations/work-in-progress.png';
import styles from './MessageTemplates.module.scss';
import cardStyles from '../../styles/Cards.module.scss';
import fontStyles from '../../styles/Fonts.module.scss';

const defaultErrorMessage = 'An error occurred. Please try again later.';
const defaultSuccessMessage = 'Success!';

export const ErrorTemplate = ({
	errorMessage = defaultErrorMessage,
	additionalStyles = {},
	warning = true,
}) => {
	return (
		<div className={styles.messageContainer} style={additionalStyles}>
			<img src={warning ? WarningLogo : ErrorLogo} alt="Error" />
			<p>{errorMessage}</p>
		</div>
	);
};

export const SuccessTemplate = ({
	successMessage = defaultSuccessMessage,
	logo = SuccessLogo,
	additionalStyles = {},
}) => {
	return (
		<div
			className={`${styles.messageContainer} ${styles.successMessage}`}
			style={additionalStyles}>
			<img src={logo} alt="Success" />
			<p>{successMessage}</p>
		</div>
	);
};

export const LoadingTemplate = ({
	loadingMessage = 'Loading...',
	additionalStyles = {},
}) => {
	return (
		<div className={styles.messageContainer} style={additionalStyles}>
			<CircularProgress />
			<p>{loadingMessage}</p>
		</div>
	);
};

export const LoadingFullScreenTemplate = ({ loadingMessage = '' }) => {
	return (
		<div className={styles.fullPageMessage}>
			<div className={styles.contentContainer}>
				<CircularProgress />
				<p>{loadingMessage}</p>
			</div>
		</div>
	);
};

export const IssueFoundFullScreenTemplate = ({
	message = 'No data found',
	illustration = WorkInProgressIllustration,
	handleSetup,
}) => {
	// TODO: Add a "Complete setup" button that opens the setup popup. Get a prop that determines if the button should be shown
	return (
		<div className={styles.fullPageMessage}>
			<div className={styles.contentContainer}>
				<img src={illustration} alt="Success" style={{ maxWidth: '400px' }} />
				<p style={{ height: 'auto' }}>{message}</p>
				{handleSetup && (
					<Button variant="contained" onClick={handleSetup}>
						Complete setup
					</Button>
				)}
			</div>
		</div>
	);
};

export function NoBuildingsBanner() {
	return (
		<div className={styles.fullPageMessage} style={{ padding: 36 }}>
			<div className={styles.contentContainer}>
				<img src={NoBuildingFoundLogo} alt="No Buildings founds" />
				<div style={{ maxWidth: '320px' }}>
					<p className={fontStyles.keyTitle} style={{ paddingBottom: '10px' }}>
						No buildings found! Please reach out to us to begin adding.
					</p>
					<div
						className={`${cardStyles.verticalContent} ${cardStyles.allCentered}`}>
						<strong>Contact us at</strong>
						<br />
						<div className={fontStyles.cardDescription}>
							Email:{' '}
							<a href="mailto:info@joulea.com" style={{ color: '#212121' }}>
								info@joulea.com
							</a>
						</div>
						Phone: +1 (404) 590-8397
					</div>
				</div>
			</div>
		</div>
	);
}
