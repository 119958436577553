import { useState } from 'react';
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Zoom,
	CircularProgress,
	IconButton,
} from '@mui/material';
import {
	OpenInNew as OpenInNewIcon,
	Delete as DeleteIcon,
	InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { BootstrapTooltip } from '../../../../inputSheet/DropdownWithDesc';
import {
	validateFiles,
	DEFAULT_ALLOWED_FILES,
	openFile,
	allFileTypes,
	MAX_FILE_SIZE,
	awsFileUploader,
	awsFileDelete,
} from '../../../../utils/fileUpload/fileHelpers';
import { useDispatch } from 'react-redux';
import {
	addDocument,
	deleteDocument,
} from '../../../../../redux/setup/setupSlice';

// Format the files to be uploaded
export const formatFileList = (files) =>
	Array.from(files).map((file) => {
		const userFileName = file.name;
		const extensionIndex = userFileName?.lastIndexOf('.') || -1;
		const fileExtension = userFileName.slice(extensionIndex);
		const awsFileName = `${userFileName.slice(
			0,
			extensionIndex
		)}-${Date.now()}${fileExtension}`;

		return {
			file,
			userFileName,
			awsFileName,
			url: null,
		};
	});

const FileUpload = ({
	documentName,
	documentTypeIndex,
	description,
	propertyId,
	documents,
	inputJsonKey,
	allowedFileTypes = DEFAULT_ALLOWED_FILES,
	setShowSnackbar,
	isRequired,
}) => {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(true);
	const [fileProgress, setFileProgress] = useState(0);
	const supportedFileTypes = allowedFileTypes.join(', ').toUpperCase();
	const fileCount = documents?.length || 0;

	const handleToggle = () => {
		setExpanded(!expanded);
	};

	// Upload file to S3 and update the input sheet
	const handleFileUpload = async (event) => {
		const files = event.target.files;
		const { error } = validateFiles(files, allowedFileTypes, MAX_FILE_SIZE);
		if (error) {
			const { message } = error;
			setShowSnackbar(message, 'error');
			return;
		}

		try {
			setFileProgress(1);
			const results = await awsFileUploader(
				formatFileList(files),
				documentTypeIndex,
				setFileProgress,
				propertyId
			);
			if (results) {
				dispatch(addDocument(results));
				setFileProgress(0);
			}
		} catch (error) {
			setShowSnackbar(error.message, 'error');
		}
	};

	// Delete file from S3 and update the input sheet
	const handleFileDelete = async (file) => {
		try {
			const deleted = await awsFileDelete(
				file.aws_file_name,
				setFileProgress,
				propertyId
			);
			if (!deleted) {
				throw new Error('Error deleting file. Please try again later.');
			}

			dispatch(deleteDocument(file.aws_file_name));
			setFileProgress(0);
		} catch (error) {
			setShowSnackbar(error.message, 'error');
		}
	};

	return (
		<div className="MultipleUpload">
			<Accordion expanded={expanded} onChange={handleToggle}>
				<AccordionSummary className="accordion-summary">
					<div className="summary">
						<FileTitle
							documentName={documentName}
							description={description}
							supportedFileTypes={supportedFileTypes}
							isRequired={isRequired}
						/>
						<FileUploadButton
							fileProgress={fileProgress}
							inputJsonKey={inputJsonKey}
							handleFileUpload={handleFileUpload}
							fileCount={fileCount}
							allowedFileTypes={allowedFileTypes}
						/>
					</div>
				</AccordionSummary>
				<AccordionDetails className="accordion-detail">
					<AccordionContents
						fileCount={fileCount}
						documents={documents}
						handleFileDelete={handleFileDelete}
						fileProgress={fileProgress}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default FileUpload;

function AccordionContents({
	fileCount,
	documents,
	handleFileDelete,
	fileProgress,
}) {
	return (
		<div className="accordionContents">
			{fileCount > 0 && (
				<List dense={true}>
					{documents?.map((file, index) => (
						<ListItem key={index} style={{ height: '50px' }}>
							<div className="files">
								<ListItemText primary={`${file.user_file_name}`} />
								<div className="actions-container">
									<IconButton onClick={() => openFile(file.url)}>
										<OpenInNewIcon
											sx={{
												cursor: 'pointer',
												color: '#004225',
											}}
										/>
									</IconButton>
									<IconButton
										onClick={() => handleFileDelete(file)}
										disabled={fileProgress === -1}>
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												color: fileProgress === -1 ? '#A8A8A8' : '#E7836C',
											}}
										/>
									</IconButton>
								</div>
							</div>
						</ListItem>
					))}
				</List>
			)}
		</div>
	);
}

function FileTitle({
	documentName,
	description,
	supportedFileTypes,
	isRequired,
}) {
	return (
		<div className="container">
			<div className="header">
				<Typography className="document-name">
					{documentName}
					{isRequired ? <span style={{ color: 'red' }}>*</span> : ''}
				</Typography>
				<BootstrapTooltip
					title={
						<div style={{ fontSize: '0.9rem', padding: '8px' }}>
							{description}
						</div>
					}
					arrow
					placement={'top'}
					TransitionComponent={Zoom}>
					<InfoOutlinedIcon sx={{ color: '#004225', fontSize: '18px' }} />
				</BootstrapTooltip>
			</div>
			<Typography className="valid-filetypes">
				File types: <span className="types">{supportedFileTypes}</span>
			</Typography>
		</div>
	);
}

function FileUploadButton({
	fileProgress,
	inputJsonKey,
	handleFileUpload,
	fileCount,
	allowedFileTypes,
}) {
	return (
		<div className="file-upload-container">
			<div className="fileupload">
				{fileProgress === -1 ? (
					// File progress is -1 when the file is being deleted
					<span className="deleteInfo">Deleting...</span>
				) : fileProgress === 0 ? (
					<span
						className="btn valid-file"
						onClick={(event) => {
							event.stopPropagation();
							document.getElementById(inputJsonKey).click();
						}}>
						<div className="uploadButton">
							<AddRoundedIcon />
							<span>UPLOAD</span>
						</div>
						<input
							type="file"
							name="fileInput"
							id={inputJsonKey}
							multiple
							onChange={handleFileUpload}
							accept={allowedFileTypes
								.map((type) => allFileTypes[type])
								.join(', ')}
							style={{ display: 'none' }}
						/>
					</span>
				) : (
					<div className="actions-container">
						{fileProgress > 1 ? (
							<CircularProgress
								variant="determinate"
								value={fileProgress === 100 ? 99 : fileProgress}
							/>
						) : (
							<CircularProgress variant="indeterminate" />
						)}
						{fileProgress > 1 && (
							<span className="progress-number">
								{fileProgress === 100 ? 99 : fileProgress}%
							</span>
						)}
					</div>
				)}
			</div>
			{fileCount > 0 && (
				<Typography className="file-count">
					{fileCount} {fileCount > 1 ? 'files' : 'file'} uploaded
				</Typography>
			)}
		</div>
	);
}
