import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getDocumentInfo = async (): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		'/setup-info/get-document-definition'
	);
	return response.data;
};

export const getDocuments = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/documents`
	);
	return response.data;
};

export const addDocument = async (
	propertyId: string,
	fileName: string,
	awsFileName: string,
	documentTypeId: number
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/documents`,
		{
			fileName,
			awsFileName,
			documentTypeId,
		}
	);
	return response.data;
};

export const deleteDocument = async (
	propertyId: string,
	documentId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.delete(
		`/v2/p/${propertyId}/setup/documents`,
		{
			params: { documentId },
		}
	);
	return response.data;
};

export const getPropertyEnergySetup = async (
	propertyId: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/property-energy`
	);
	return response.data;
};

export const updatePropertyEnergySetup = async (
	propertyId: string,
	column_name: string,
	column_value: string,
	id: number
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.put(
		`/v2/p/${propertyId}/setup/property-energy`,
		{
			id,
			column_name,
			column_value,
		}
	);
	return response.data;
};

export const addPropertyEnergySetup = async (
	propertyId: string,
	column_name: string,
	column_value: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/property-energy`,
		{
			column_name,
			column_value,
		}
	);
	return response.data;
};
