import { useSelector } from 'react-redux';
import { basNetworkContextPageKeys } from '../../const';
import BasDocuments from './BasDocuments';
import PreferredEquipmentTypes from './PreferredEquipmentTypes';
import { useEffect, useState } from 'react';
import { CongratsTemplate } from '../../CommonTemplates';

function NetworkContextOnboarding({ onClose, setShowSnackbar }) {
	const [pageNumber, setPageNumber] = useState(0);

	const networkOnboardingPages = {
		[basNetworkContextPageKeys.BAS_DOCUMENTS]: (
			<BasDocuments
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basNetworkContextPageKeys.PREFERRED_EQUIPMENT_TYPES]: (
			<PreferredEquipmentTypes
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basNetworkContextPageKeys.SUCCESS]: (
			<NetworkContextSuccess onClose={onClose} />
		),
	};

	return <>{networkOnboardingPages[pageNumber]}</>;
}

function NetworkContextSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your information has been saved successfully."
			onClick={onClose}
		/>
	);
}

export default NetworkContextOnboarding;
