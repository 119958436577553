import { useSelector } from 'react-redux';
import EnergyStarConnectionSteps from './EnergyStarConnectionSteps';
import EnergyStarSharePropertySteps from './EnergyStarSharePropertySteps';
import {
	AlreadyConnected,
	AlreadyShared,
	SuccessPopup,
} from './EnergyStarSuccess';
import { GetAccountNumber, GetPropertyNumber } from './GetEnergyStarInputs';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getEnergySetup } from '../../../../../redux/setup/setupSlice';
import { energyStarJsonKeys, energyStarPageKeys } from '../../const';
import { getInitialEnergyStarPageNumber } from '../../const';

function EnergyStarOnboarding({ onClose, setShowSnackbar }) {
	const dispatch = useDispatch();
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding?.prop_id;
	const buildingName = selectedBuilding?.prop_name;

	// Get the energy setup data
	const { energySetupData } = useSelector((state) => state.setup);
	const id = energySetupData?.id;
	const accountNumber = energySetupData?.[energyStarJsonKeys.ACCOUNT_NUMBER];
	const propertyNumber = energySetupData?.[energyStarJsonKeys.PROPERTY_NUMBER];
	const isConnected = energySetupData?.[energyStarJsonKeys.IS_CONNECTED];
	const isShared = energySetupData?.[energyStarJsonKeys.IS_SHARED];

	// The current page number
	const [currentPage, setCurrentPage] = useState(
		getInitialEnergyStarPageNumber(energySetupData)
	);

	useEffect(() => {
		if (propertyId) {
			// Fetch the energy setup data
			dispatch(getEnergySetup({ propertyId }));
		}
	}, [dispatch, propertyId]);

	// The pages to display
	const energyStarPages = {
		[energyStarPageKeys.GET_ACCOUNT_NUMBER]: (
			<GetAccountNumber
				accountNumber={accountNumber}
				setShowSnackbar={setShowSnackbar}
				propertyId={propertyId}
				setCurrentPage={setCurrentPage}
				id={id}
			/>
		),
		[energyStarPageKeys.GET_PROPERTY_NUMBER]: (
			<GetPropertyNumber
				accountNumber={accountNumber}
				propertyNumber={propertyNumber}
				buildingName={buildingName}
				setShowSnackbar={setShowSnackbar}
				propertyId={propertyId}
				setCurrentPage={setCurrentPage}
				id={id}
			/>
		),
		[energyStarPageKeys.CONNECT_ACCOUNT]: (
			<EnergyStarConnectionSteps
				accountNumber={accountNumber}
				isConnected={isConnected}
				propertyId={propertyId}
				setCurrentPage={setCurrentPage}
				id={id}
			/>
		),
		[energyStarPageKeys.SHARE_PROPERTY]: (
			<EnergyStarSharePropertySteps
				propertyNumber={propertyNumber}
				isShared={isShared}
				setCurrentPage={setCurrentPage}
				propertyId={propertyId}
				id={id}
			/>
		),
		[energyStarPageKeys.SUCCESS]: (
			<SuccessPopup onClose={onClose} buildingName={buildingName} />
		),
		[energyStarPageKeys.ALREADY_CONNECTED]: (
			<AlreadyConnected setCurrentPage={setCurrentPage} />
		),
		[energyStarPageKeys.ALREADY_SHARED]: (
			<AlreadyShared onClose={onClose} buildingName={buildingName} />
		),
	};

	// Render the current page
	return <>{energyStarPages[currentPage]}</>;
}

export default EnergyStarOnboarding;
