import { ReactNode } from 'react';
import styles from './Field.module.scss';

interface FieldProps {
	/** HTML Id for the input component for the labels to reference */
	inputId?: string;
	/** Main label for the field */
	label: string;
	/** Helper text under the label */
	helperText?: string;
	/** Flag set if subfield */
	subfield?: boolean;
	/** Input component */
	children?: ReactNode;
	// For center-aligned fields
	centerAlign?: boolean;
	// Any additional classes
	additionalClasses?: string;
	// Any additional styles
	additionalStyles?: Record<string, string>;
}

function Field({
	inputId,
	label,
	helperText,
	subfield = false,
	children,
	centerAlign = false,
	additionalClasses = '',
	additionalStyles = {},
}: FieldProps) {
	return (
		<div
			className={`${
				subfield ? styles.popupSubfieldContainer : styles.popupFieldContainer
			} ${additionalClasses}`}
			style={centerAlign ? { alignItems: 'center' } : additionalStyles}>
			<div className={styles.info__container}>
				<label
					className={
						subfield
							? styles['popupInputLabel--subfield']
							: styles.popupInputLabel
					}
					htmlFor={inputId}>
					{label}
				</label>
				{helperText ? (
					<label
						className={`${styles['info__helper-text']} ${
							subfield && styles['info__helper-subfield']
						}`}
						htmlFor={inputId}>
						{helperText}
					</label>
				) : null}
			</div>
			<div className={styles.popupInputContainer}>{children}</div>
		</div>
	);
}

export default Field;
