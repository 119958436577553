import { useSelector } from 'react-redux';
import { useState, useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { basBacnetSetupPageKeys, basJsonKeys } from '../../const';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import Field from '../../../../inputSheet/Field';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';
import toggleButtonStyles from '../../../../inputSheet/ToggleButton.module.scss';

const BacnetDetails = ({ setShowSnackbar, setPageNumber }) => {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const dispatch = useDispatch();

	const propertyId = selectedBuilding?.prop_id; // Optional chaining for safety

	const { energySetupData } = useSelector((state) => state.setup) || {};
	const {
		is_on_same_network,
		is_bbmd_present,
		is_primary_bacnet,
		has_bacnet_capability,
		does_export_as_bacnet,
	} = energySetupData || {};

	const [isOnSameNetwork, setIsOnSameNetwork] = useState(
		is_on_same_network ?? null
	);
	const [isBbmdPresent, setIsBbmdPresent] = useState(is_bbmd_present ?? null);
	const [isPrimaryBacnet, setIsPrimaryBacnet] = useState(
		is_primary_bacnet ?? null
	);
	const [hasBacnetCapability, setHasBacnetCapability] = useState(
		has_bacnet_capability ?? null
	);
	const [doesExportAsBacnet, setDoesExportAsBacnet] = useState(
		does_export_as_bacnet ?? null
	);

	const [loading, setLoading] = useState(false);

	const allFilled =
		isOnSameNetwork === null ||
		isBbmdPresent === null ||
		isPrimaryBacnet === null ||
		(isPrimaryBacnet === false &&
			(hasBacnetCapability === null || doesExportAsBacnet === null));

	const handleNext = async () => {
		setLoading(true);

		const updates = [
			{
				column_name: basJsonKeys.IS_ON_SAME_NETWORK,
				column_value: isOnSameNetwork,
			},
			{ column_name: basJsonKeys.IS_BBMD_PRESENT, column_value: isBbmdPresent },
			{
				column_name: basJsonKeys.IS_PRIMARY_BACNET,
				column_value: isPrimaryBacnet,
			},
			...(isPrimaryBacnet === false
				? [
						{
							column_name: basJsonKeys.HAS_BACNET_CAPABILITY,
							column_value: hasBacnetCapability,
						},
						{
							column_name: basJsonKeys.DOES_EXPORT_AS_BACNET,
							column_value: doesExportAsBacnet,
						},
				  ]
				: []),
		];

		try {
			for (const update of updates) {
				if (update.column_value !== null) {
					// Skip null values
					await dispatch(
						updateEnergySetup({
							propertyId,
							column_name: update.column_name,
							column_value: update.column_value,
							...(energySetupData.id && { id: energySetupData.id }),
						})
					);
					dispatch(
						updateEnergyRow({
							[update.column_name]: update.column_value,
						})
					);
				}
			}
			setPageNumber(basBacnetSetupPageKeys.BACNET_CONFIGURATION);
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ToggleField
				id={'isOnSameNetwork'}
				label={'Are BAS/BACnet and Joulea device on the same network?'}
				helperText={''}
				value={isOnSameNetwork}
				onChange={setIsOnSameNetwork}
			/>
			<ToggleField
				id={'isBbmdPresent'}
				label={'Are there any BBMDs in the BAS BACnet network?'}
				helperText={''}
				value={isBbmdPresent}
				onChange={setIsBbmdPresent}
			/>
			<ToggleField
				id={'isPrimaryBacnet'}
				label={'Are the primary BAS communications BACnet?'}
				helperText={''}
				value={isPrimaryBacnet}
				onChange={setIsPrimaryBacnet}
			/>
			{isPrimaryBacnet === false && (
				<ToggleField
					id={'hasBacnetCapability'}
					label={"Does the building's network have BACnet capability?"}
					helperText={''}
					value={hasBacnetCapability}
					onChange={setHasBacnetCapability}
				/>
			)}
			{isPrimaryBacnet === false && (
				<ToggleField
					id={'doesExportAsBacnet'}
					label={'Does the building export BAS points as BACnet points?'}
					helperText={''}
					value={doesExportAsBacnet}
					onChange={setDoesExportAsBacnet}
				/>
			)}
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					disabled={allFilled || loading}
					variant="contained"
					onClick={handleNext}>
					Next
				</LoadingButton>
			</div>
		</>
	);
};

export default BacnetDetails;

const ToggleField = ({ id, label, helperText, value, onChange }) => {
	const toggleOptions = {
		Y: true,
		N: false,
	};

	const handleToggleChange = useCallback(
		(e, newValue) => {
			onChange(newValue);
		},
		[onChange]
	);

	return (
		<Field inputId={id} label={label} helperText={helperText}>
			<ToggleButtonGroup
				className={toggleButtonStyles.popupButtonGroupContainer}
				value={value}
				exclusive
				onChange={handleToggleChange}>
				{Object.entries(toggleOptions).map(([label, value]) => (
					<ToggleButton
						key={label}
						value={value}
						className={toggleButtonStyles.popupButton}>
						{label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</Field>
	);
};
