import { formatString } from '../../../utils';
import { uploadFile, deleteFile } from '../../../api/awsApi';
import React from 'react';

export const S3_BUCKET = process.env.REACT_APP_aws_bucketName;
export const REGION = process.env.REACT_APP_aws_region;

export const MAX_FILE_SIZE = 300;
export const DEFAULT_ALLOWED_FILES = ['pdf'];
export const allFileTypes: Record<string, string> = {
	csv: 'text/csv',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	pdf: 'application/pdf',
};
export const fileTypeErrorMessage =
	'You have uploaded a file of type {}. Please upload a {} file.';

export const maxFileSizeErrorMessage =
	'File size exceeds the maximum limit of {}MB.';

export const URL_EXPIRATION_TIME = 3600;

type FileListItem = {
	userFileName: string;
	url: string;
	awsFileName: string;
};

export const defaultFile: FileListItem = {
	userFileName: '',
	url: '',
	awsFileName: '',
};

type FileUploadItem = {
	file: File;
	userFileName: string;
	url: string;
	awsFileName: string;
};

export const validateFiles = (
	files: File[],
	allowedFileTypes: string[],
	maxFileSize: number
): {
	isValid: boolean;
	error: {
		file: File;
		message: string;
	} | null;
} => {
	const result = {
		isValid: true,
		error: null as { file: File; message: string } | null,
	};

	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const fileType = file.type;
		const fileSize = file.size;

		if (
			!allowedFileTypes.some(
				(type) => fileType === allFileTypes[type as keyof typeof allFileTypes]
			)
		) {
			result.isValid = false;
			const message = formatString(
				fileTypeErrorMessage,
				fileType,
				allowedFileTypes.join('/ ').toUpperCase()
			);
			result.error = {
				file,
				message,
			};
			return result;
		}

		if (fileSize > maxFileSize * 1024 * 1024) {
			result.isValid = false;
			const message = formatString(maxFileSizeErrorMessage, maxFileSize);
			result.error = {
				file,
				message,
			};
			return result;
		}
	}

	return result;
};

export const getExtensionFromFileName = (fileName: String) => {
	const dotIndex = fileName.lastIndexOf('.');
	if (dotIndex === -1) {
		return ''; // Return empty string if no extension found
	}
	return fileName.slice(dotIndex + 1).toLowerCase();
};

export const awsFileUploader = async (
	files: FileUploadItem[],
	documentTypeIndex: number,
	setFileProgress: (fileProgress: number) => void,
	propertyId: string
): Promise<any> => {
	const uploadPromises = files.map(async (fileUploadItem) => {
		const formData = new FormData();
		formData.append('file', fileUploadItem.file);
		formData.append('awsFileName', fileUploadItem.awsFileName);
		formData.append('userFileName', fileUploadItem.userFileName);
		formData.append('documentTypeId', documentTypeIndex.toString());

		const response = await uploadFile(propertyId, formData, setFileProgress);
		if (response.status !== 201) {
			throw new Error('Failed to upload file');
		}

		const data = response.data.data;
		const uploadData = {
			url: data,
			user_file_name: fileUploadItem.userFileName,
			aws_file_name: fileUploadItem.awsFileName,
			document_type_id: { id: Number(documentTypeIndex) },
		};
		return uploadData;
	});

	try {
		const uploadResults = await Promise.all(uploadPromises);
		return uploadResults;
	} catch (error) {
		setFileProgress(0);
		throw error;
	}
};

export const awsFileDelete = async (
	Keys: string | string[],
	progressSetter: React.Dispatch<React.SetStateAction<number>>,
	propertyId: string
): Promise<boolean> => {
	const deleteKeys = Array.isArray(Keys) ? Keys : [Keys];
	const totalKeys = deleteKeys.length;
	let deletedKeys = 0;

	const handleDeleteComplete = () => {
		deletedKeys++;
		const progress = Math.round((deletedKeys / totalKeys) * 100);
		progressSetter(progress);
	};

	const deletePromises = deleteKeys.map(async (awsFileName) => {
		try {
			const data = { awsFileName };
			await deleteFile(propertyId, data);

			handleDeleteComplete();
		} catch (error) {
			throw new Error(`Error deleting files: ${error}`);
		}
	});

	try {
		// Set progress to -1 to indicate that the deletion process has started
		progressSetter(-1);
		await Promise.all(deletePromises);
		return true;
	} catch (error) {
		// Keep it as 100 if the deletion process fails
		// This will continue to show the file
		progressSetter(100);
		return false;
	}
};

export const openFile = (url: string) => {
	window.open(url, '_blank', 'noopener,noreferrer');
};
