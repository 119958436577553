import { useState } from 'react';
import { basBacnetSetupPageKeys } from '../../const';
import { CongratsTemplate } from '../../CommonTemplates';
import BacnetDetails from './BacnetDetails';
import BacnetConfiguration from './BacnetConfiguration';

const BACnetSetupOnboarding = ({ onClose, setShowSnackbar }) => {
	const [pageNumber, setPageNumber] = useState(0);

	const basBacnetSetupPages = {
		[basBacnetSetupPageKeys.BACNET_DETAILS]: (
			<BacnetDetails
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basBacnetSetupPageKeys.BACNET_CONFIGURATION]: (
			<BacnetConfiguration
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basBacnetSetupPageKeys.SUCCESS]: <BACNetSetupSuccess onClose={onClose} />,
	};

	return <>{basBacnetSetupPages[pageNumber]}</>;
};

function BACNetSetupSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your information has been saved successfully."
			onClick={onClose}
		/>
	);
}

export default BACnetSetupOnboarding;
