import { useState } from 'react';
import { basDeploymentPageKeys } from '../../const';
import { CongratsTemplate } from '../../CommonTemplates';
import PrimaryBasCommunications from './PrimaryBASCommunications';
import AvailableBasCommunications from './AvailableBasCommunications';

const DeploymentConfigurationOnboarding = ({ onClose, setShowSnackbar }) => {
	const [pageNumber, setPageNumber] = useState(0);

	const deploymentConfigurationPages = {
		[basDeploymentPageKeys.PRIMARY_BAS_COMMUNICATIONS]: (
			<PrimaryBasCommunications
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basDeploymentPageKeys.AVAILABLE_BAS_COMMUNICATIONS]: (
			<AvailableBasCommunications
				setShowSnackbar={setShowSnackbar}
				setPageNumber={setPageNumber}
			/>
		),
		[basDeploymentPageKeys.SUCCESS]: (
			<DeploymentConfigurationSuccess onClose={onClose} />
		),
	};

	return <>{deploymentConfigurationPages[pageNumber]}</>;
};

function DeploymentConfigurationSuccess({ onClose }) {
	return (
		<CongratsTemplate
			title="Thank you!"
			message="Your information has been saved successfully."
			onClick={onClose}
		/>
	);
}

export default DeploymentConfigurationOnboarding;
