import { basJsonKeys, energyStarJsonKeys } from './const';

import {
	DocumentListObject,
	DocumentDefinitionObject,
} from './dataSharingInterfaces';

// Calculate the progress of the energy direct module
export const calculateEnergyDirectProgress = (
	doesAccountExist: Boolean,
	utilityBills: Array<DocumentListObject>,
	consumptionDocs: Array<DocumentListObject>
) => {
	return consumptionDocs?.length > 0 || utilityBills?.length > 0
		? 100
		: typeof doesAccountExist === 'boolean'
		? 50
		: 0;
};

// Calculate the progress of the energy star module
export const calculateEnergyStarProgress = (
	energySetupData: Record<string, any>
) => {
	if (!energySetupData) {
		return 0;
	}

	return energySetupData?.[energyStarJsonKeys.IS_SHARED]
		? 100
		: energySetupData?.[energyStarJsonKeys.PROPERTY_NUMBER]
		? 75
		: energySetupData?.[energyStarJsonKeys.IS_CONNECTED]
		? 50
		: energySetupData?.[energyStarJsonKeys.ACCOUNT_NUMBER]
		? 25
		: 0;
};

// Calculate the progress of Deployment Configuration module
export const calculateDeploymentConfigurationProgress = (
	energySetupData: Record<string, any>
) => {
	if (!energySetupData) {
		return 0;
	}

	// Validation function for both AVAILABLE_BAS_COMMUNICATIONS and PRIMARY_BAS_COMMUNICATIONS
	const isBasCommunicationsValid = (data: Record<string, any>) => {
		if (!data) {
			return false;
		}

		// Check if "selected" key exists and is a non-empty array
		const selectedKeys = data.selected;
		if (!Array.isArray(selectedKeys) || selectedKeys.length === 0) {
			return false;
		}

		// If "Others" exists in the list and the "Others" key in data is non-empty, return true
		if (
			selectedKeys.includes('Others') &&
			data.others &&
			data.others.length > 0
		) {
			return true;
		}

		// If "Others" is not in the list but selectedKeys is non-empty, return true
		if (!selectedKeys.includes('Others') && selectedKeys.length > 0) {
			return true;
		}

		// Default: invalid
		return false;
	};

	// Validate AVAILABLE_BAS_COMMUNICATIONS
	const isValidAvailableBas = isBasCommunicationsValid(
		energySetupData?.[basJsonKeys.AVAILABLE_BAS_COMMUNICATIONS]
	);

	// Validate PRIMARY_BAS_COMMUNICATIONS
	const isValidPrimaryBas = isBasCommunicationsValid(
		energySetupData?.[basJsonKeys.PRIMARY_BAS_COMMUNICATIONS]
	);

	// Determine progress based on validation
	return isValidAvailableBas ? 100 : isValidPrimaryBas ? 50 : 0;
};

// Calculate the progress of Device Network Configuration module
export const calculateDeviceNetworkConfigurationProgress = (
	energySetupData: Record<string, any>
): number => {
	if (!energySetupData) {
		return 0;
	}

	// The first key is a JSON object with three keys, all needing values of length 15
	const staticNetwork = energySetupData?.[basJsonKeys.STATIC_NETWORK];
	const isStaticNetworkValid =
		staticNetwork &&
		Object.values(staticNetwork).length === 3 && // Ensure it has 3 keys (IP Address, Subnet Mask, Default Gateway)
		Object.values(staticNetwork).every(
			(value) => typeof value === 'string' && value.length === 15
		);

	const hasOutboundInternetAccess =
		energySetupData?.[basJsonKeys.HAS_OUTBOUND_INTERNET_ACCESS];
	const hasGivenPortAccess =
		!!energySetupData?.[basJsonKeys.HAS_GIVEN_PORT_ACCESS];

	if (isStaticNetworkValid && hasOutboundInternetAccess) {
		return 100;
	}

	if (isStaticNetworkValid && !hasOutboundInternetAccess) {
		return hasGivenPortAccess ? 100 : 50;
	}

	return 0;
};

// Calculate the progress of Bacnet Setup module
export const calculateBacnetSetup = (energySetupData: Record<string, any>) => {
	if (!energySetupData) {
		return 0;
	}

	const requiredConfigurationKeys = [
		basJsonKeys.DEVICE_ID,
		basJsonKeys.BACNET_COMMUNICATION_TYPE,
		basJsonKeys.BACNET_DEVICE_CONFIGURATION_MODE,
	];

	const requiredDetailKeys = [
		basJsonKeys.IS_ON_SAME_NETWORK,
		basJsonKeys.IS_BBMD_PRESENT,
		basJsonKeys.IS_PRIMARY_BACNET,
	];

	const conditionalKeys = [
		basJsonKeys.HAS_BACNET_CAPABILITY,
		basJsonKeys.DOES_EXPORT_AS_BACNET,
	];

	const areAllKeysNonEmpty = (data: Record<string, any>, keys: string[]) =>
		keys.every((key) => data?.[key] !== null && data?.[key] !== undefined);

	const areConditionalKeysValid = (
		data: Record<string, any>,
		conditionalKeys: string[]
	) => {
		const value = data?.[basJsonKeys.IS_PRIMARY_BACNET];
		if (value === false) {
			return conditionalKeys.every(
				(key) => data?.[key] !== null && data?.[key] !== undefined
			);
		}
		return true;
	};

	return areAllKeysNonEmpty(energySetupData, requiredConfigurationKeys)
		? 100
		: areAllKeysNonEmpty(energySetupData, requiredDetailKeys) &&
		  areConditionalKeysValid(energySetupData, conditionalKeys)
		? 50
		: 0;
};

// Calculate the progress of Network Context module
export const calculateNetworkContextProgress = (
	energySetupData: Record<string, any>,
	documentList: Array<DocumentListObject>,
	documentDefinition: Array<DocumentDefinitionObject>
): number => {
	if (!energySetupData || !documentList?.length) {
		return 0;
	}

	const preferredEquipmentTypes =
		energySetupData?.[basJsonKeys.PREFERRED_EQUIPMENT_TYPES];

	const hasValidPreferredEquipmentTypes =
		typeof preferredEquipmentTypes === 'string' &&
		preferredEquipmentTypes.trim().length > 0;

	return hasValidPreferredEquipmentTypes
		? 100
		: calculateDocumentProgress(documentList, documentDefinition) === 100
		? 50
		: 0;
};

// Calculate the progress of the document module
export const calculateDocumentProgress = (
	documentList: Array<DocumentListObject>,
	documentDefinition: Array<DocumentDefinitionObject>
) => {
	if (!documentList?.length) {
		return 0;
	}

	// Total number of required document types
	let countOfRequiredTypes = 0;

	// Calculate the number of required document types filled
	const countOfRequiredDocTypesFilled = documentDefinition.reduce(
		(acc, _, docTypeIndex) => {
			// Check if the document type is required
			if (!documentDefinition[docTypeIndex].isRequired) {
				return acc;
			}

			countOfRequiredTypes++;
			const uploadedDoc = documentList.find(
				(docItem) =>
					docItem.document_type_id.id === documentDefinition[docTypeIndex].id
			);
			if (uploadedDoc) {
				acc++;
			}
			return acc;
		},
		0
	);

	// Calculate the progress
	return (countOfRequiredDocTypesFilled / countOfRequiredTypes) * 100;
};

// Calculate the progress of the drone logistics module
export const calculateDroneLogisticsProgress = (
	droneLogisticsData: Record<string, any>
) => {
	if (!droneLogisticsData) {
		return 0;
	}

	// Total number of fields filled in the drone logistics module
	let pagesFilled = 0;
	// Check if each field is filled and increment the pagesFilled accordingly
	const selectedDates = droneLogisticsData.selectedDates;

	if (droneLogisticsData.chargingLocations) pagesFilled++; // Compulsory field
	if (
		Array.isArray(selectedDates) &&
		selectedDates.length > 0 // Compulsory field
	)
		pagesFilled++;

	// Check preferredLocations
	if (droneLogisticsData.preferredLocations) {
		pagesFilled++;
	} else {
		// Check takeoffLocations if preferredLocations is empty
		const takeoffLocations = Object.values(droneLogisticsData.takeoffLocations);
		if (
			takeoffLocations.length > 0 &&
			takeoffLocations.some((location) => (location as any).selected === true)
		) {
			pagesFilled++;
		}
	}

	return pagesFilled;
};
